import Cards from "./components/Cards";
import React from "react";
import "../src/App.css";
import Logo from "../src/assets/logo.png";
import Form from "../src/components/Form";

function App() {
  return (
    <div className="App">
      <header>
        <img src={Logo} alt="logo" />
      </header>
      <h1>
        Du vendredi 30 Août au dimanche 1er Septembre, Swann et Davy vous invite
        à partager un week-end olympique !
      </h1>
      <div className="card-container">
        <Cards
          day="Vendredi"
          content={<p>19h - Soirée d'ouverture et découverte des équipes</p>}
        />
        <Cards
          day="Samedi"
          content={
            <p>
              10h - Début des olympiades , alcools et autres victuailles en
              libre service. <br /> 20h - Soirée de célébration
            </p>
          }
        />
        <Cards
          day="Dimanche"
          content={<p>Décuvage et tout le monde dégage !</p>}
        />
      </div>
      <Form />
    </div>
  );
}

export default App;
