import { useState } from 'react';
import axios from 'axios';
import { AlertError } from './views/AlertError';
import { AlertSuccess } from './views/AlertSuccess';


export const Form = () => {
    const initialFormData = {
        name: '',
        surname: '',
        number: '',
    };

    const [showPopup, setShowPopup] = useState(false);
    const [formData, setFormData] = useState(initialFormData);
    const [formSuccess, setFormSuccess] = useState('');
    const [formErrors, setFormErrors] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            await axios.post('https://api.olympiade.me:3000/api/v1/person', formData);
            setFormSuccess("Inscription réussie !");
            setFormData(initialFormData);
            setShowPopup(true);
            console.log('ici');
        } catch (err) {
            handleErrors(err);
        }
    };

    const handleErrors = (err) => {
        if (err.response.data && err.response.data.errors) {
            // Handle validation errors
            const { errors } = err.response.data;

            let errorMsg = [];
            for (let error of errors) {
                const { msg } = error;
                errorMsg.push(msg);
            }
            setShowPopup(true);
            setFormErrors(errorMsg);
        } else {
            // Handle generic error
            setFormErrors(["Échec de l'inscription. Veuillez réessayer."]);
            setShowPopup(true);
        }
    };

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
        setFormErrors([]);
        setFormSuccess('');
    };

    return (
        <div className='form-container'>

            <form onSubmit={handleSubmit} className='form'>
                {showPopup && <div className='popup'>
                    <AlertError errors={formErrors} />
                    <AlertSuccess success={formSuccess} />
                    <button className='popup-button' onClick={() => setShowPopup(false)}>Fermer</button>
                </div>}

                <div className='form-topline'>

                    <div className="form-input">
                        <h3>Prénom</h3>
                        <input
                            type='text'
                            name='name'
                            className='input'
                            value={formData.name}
                            onInput={handleChange}
                        />
                    </div>
                    <div className="form-input">
                        <h3>Nom</h3>
                        <input
                            type='text'
                            name='surname'
                            className='input'
                            value={formData.surname}
                            onInput={handleChange}
                        />
                    </div>
                </div>

                <div className="form-input">
                    <h3>N° de Téléphone</h3>
                    <input
                        type='tel'
                        name='number'
                        className='input tel'
                        value={formData.number}
                        onInput={handleChange}
                    />
                </div>
                <button type='submit' className='button' value='Submit'>Envoyer</button>
            </form>
        </div>
    );
};

export default Form;