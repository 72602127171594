export const AlertError = ({ errors }) => {
  if (errors.length === 0) {
    return <></>;
  }

  return (
    <ul className="error">
      {errors.map((error, index) => (
        <li key={index}>{error}</li>
      ))}
    </ul>
  );
};
